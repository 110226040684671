
import { Vue } from "vue-class-component";

export default class LoginView extends Vue {
  login = "";
  pass = "";
  async enviarData(): Promise<void> {
    this.$router.push({ name: "Home" });
  }
}
